<template>
  <div class="page">
    <homeHeader></homeHeader>
    <div class="newsInfoListBox">
      <newBreadCrumb></newBreadCrumb>
      <!-- <h1>新闻资讯</h1> -->
      <div class="search">
        <input type="search" @keyup.enter="handleSearch" v-model="params.keywords" placeholder="请输入关键词搜索" />
        <div class="right" @click="handleSearch">
          <i class="iconfont">{{ `\ue734` }}</i>
        </div>
      </div>
      <!-- 列表 -->
      <van-pull-refresh v-model="refreshing" @refresh="handlePullDownRefresh">
        <van-list v-model="loading" :finished="finished" :offset="10" :immediate-check="false" :loading-text="loadingText" :finished-text="finishText" @load="handleReachBottom">
          <ul class="ul">
            <li class="pc" :class="flag ? 'show' : ''" v-for="(item, index) in list" :key="index" @click="handleJump(item)">
              <img :src="item.coverUrl" alt="">
              <div class="right">
                <h2 class="text-ellipsis-5">{{ item.title }}</h2>
                <p>{{ item.date }}</p>
              </div>
            </li>
            <li class="h5" v-for="(item, index) in list" :key="index + key" @click="handleJump(item)">
              <img :src="item.coverUrl" alt="">
              <div class="right">
                <h2 class="text-ellipsis-5">{{ item.title }}</h2>
                <p>{{ item.date }}</p>
              </div>
            </li>
          </ul>
        </van-list>
      </van-pull-refresh>
    </div>
    <homeFooter></homeFooter>
  </div>
</template>
<script>
import homeHeader from '../../components/homeHeader.vue'
import homeFooter from '../../components/homeFooter.vue'
import newBreadCrumb from '../../components/newBreadCrumb.vue'
import { getNewsList } from '@/api/public.js'
import { scrollMixins } from '@/mixins/scrollMixins'
let arr = []
export default {
  mixins: [scrollMixins],
  name: '',
  props: {},
  components: { homeHeader, homeFooter, newBreadCrumb },
  data() {
    return {
      firstLoading: true,
      loading: false, //  控制数据的加载，false 代表没有正在刷新，true 代表正在刷新
      finished: false, // 是否已加载完成，加载完成后不再触发load事件
      refreshing: false, // 下拉状态是否处于加载中状态
      params: {
        pageNum: 1,
        pageSize: 10,
        keywords: '',
      },
      meetingName: '',
      total: 0, // 总条数
      pages: 0, // 总页数
      loadingText: '努力加载中...',
      finishText: '没有更多了',
      list: [],
      key: 'unique',
      flag: false,
    }
  },

  methods: {
    scrolling() {
      // 滚动条距文档顶部的距离
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      let fotBox = document.querySelector('.ul')
      let clientHeight = fotBox.clientHeight
      let offsetTop = fotBox.offsetTop
      if (scrollTop + clientHeight > offsetTop) {
        this.flag = true
      } else {
        this.flag = false
      }
    },
    handleJump(item) {
      console.log('跳转新闻详情')
      if (item.contentType == 'SELF') {
        this.$router.push({
          path: '/newsInfoDetail',
          query: {
            code: item.newsCode,
          },
        })
      } else {
        window.open(item.linkUrl)
      }
    },
    initData() {
      // 生成数据
      // for (let i = 0; i < 40; i++) {
      //   arr.push({
      //     id: new Date().getTime(),
      //   })
      // }
      this.getData()
    },
    handleSearch() {
      // if(!e.trim()) return this.$toast("请输入企业名称~")
      this.params.pageNum = 1
      this.list = []
      this.getData()
    },
    getData1() {
      let params = { ...this.params }
      getNewsList(params).then((res) => {
        console.log('1111', res)
        let arr = res?.data?.data || []
        this.newsList = arr.map((item) => {
          return {
            ...item,
            date: this.$wmtDate.fromatDate('yyyy-MM-dd', new Date(item.createTime)),
          }
        })
      })
    },
    async getData() {
      try {
        this.loading = true
        let params = {
          pageNum: this.params.pageNum,
          pageSize: this.params.pageSize,
          title: this.params.keywords,
          type: 'OFFICIAL_WEBSITE',
        }
        let res = await getNewsList(params)
        // console.log("pages", pages)
        // console.log("pageNum", pageNum)
        let arr = res?.data?.data || []
        this.list = this.params.pageNum == 1 ? [...arr] : [...this.list, ...arr]
        this.list = this.list.map((item) => {
          return {
            ...item,
            date: this.$wmtDate.fromatDate('yyyy-MM-dd', new Date(item.createTime)),
          }
        })
        this.total = res?.data?.total || 0
        this.pages = res?.data?.pages || 0
        // 追加完成后关闭loading
        this.loading = false
        this.finishText = this.list.length ? '没有更多了' : '暂无更多数据'
        console.log(`output->res`, res)
        console.log(`output->total`, this.total)
        console.log(`output->pages`, this.pages)
      } catch (error) {
        if (this.params.pageNum > 0) {
          this.params.pageNum--
        }
        console.log(`output->error`, error)
      }
    },
    // 下拉刷新
    handlePullDownRefresh() {
      setTimeout(() => {
        this.params.pageNum = 1
        this.getData()
        this.refreshing = false
        this.finished = false // 重新加载
        if (!this.refreshing) {
          this.$toast.success('刷新成功')
        }
      }, 1000)
    },
    // 上拉加载
    handleReachBottom() {
      console.log('上拉加载')
      console.log(`output->this.params.pageNum`, this.params.pageNum)
      if (this.params.pageNum < this.pages) {
        this.params.pageNum++
        this.getData().finally(() => {
          this.loading = false
        })
      } else {
        this.finished = true
      }
    },
  },
  async mounted() {
    this.params.pageNum = 1
    this.list = []
    try {
      this.firstLoading = true
      await this.initData()
      setTimeout(() => {
        this.firstLoading = false
      }, 300)
    } catch (error) {
      setTimeout(() => {
        this.firstLoading = false
      }, 300)
    }
  },
}
</script>



<style scoped lang="less">
.page {
  min-height: 100vh;
  display: flex;
  flex-flow: column wrap;
}

@themeColor: #00c4c9;
.cor-333333 {
  color: #333333;
}
@keyframes scale {
  0% {
    transform: scale(0);
    opacity: 0;
  }
}
.themeColor {
  color: @themeColor;
}

.newsInfoListBox {
  width: 100%;
  padding: 0 18.23%;
  margin: 0 auto;
  box-sizing: border-box;
  font-size: 16px;

  h1 {
    font-size: 1.625em;
    font-weight: bold;
    letter-spacing: 0.25em;
    color: #333333;
    text-align: center;
    margin-top: 3.6875em;
  }

  .search {
    background: #f5f5f5;
    height: 2.875em;
    margin: 2.75em 10.16% 1.875em;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    border-radius: 0.5em;
    overflow: hidden;

    input {
      width: 100%;
      height: 100%;
      background: #f5f5f5;
      border: none;
      outline: none;
      padding: 0 0.625em;
      box-sizing: border-box;
      flex: 1;
      font-size: 1.125em;
    }

    .right {
      width: 3.125em;
      height: 3.125em;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      i {
        font-style: normal;
        font-size: 1.25em;
      }
    }
  }

  ul {
    margin-bottom: 0.375em;

    .h5 {
      display: none;
    }

    .pc {
      display: flex;
      padding: 1.25em;
      box-sizing: border-box;
      background-color: #fff;
      border: 1px solid #dbdce1;
      border-radius: 0.5em;
      margin-top: 1.25em;
      cursor: pointer;
      &:nth-child(1) {
        margin-top: 0;
      }
      // &.show {
      //   animation-duration: 1s;
      //   animation-name:goTop;
      //   animation-delay: 1.5s;
      //   animation-fill-mode: forwards;
      // }
      &:hover {
        box-shadow: 0.0525em 0.15em 0.625em 0.125em #ccc;
      }
      @keyframes goTop {
        from {
          opacity: 0;
          transform: translateY(100px);
        }
        to {
          opacity: 1;
          transform: translateY(0%);
        }
      }

      img {
        width: 16.25em;
        height: 10em;
        object-fit: cover;
        border-radius: 0.5em;
        flex-shrink: 0;
        margin-right: 1.25em;
        animation: scale 1s;
      }

      .right {
        flex: 1;
        display: flex;
        flex-flow: column wrap;
        justify-content: space-between;

        h2 {
          font-size: 1.25em;
          color: #333333;
          font-weight: bold;
        }

        p {
          font-size: 0.75em;
          color: #a1a1a1;
          font-weight: bold;
        }
      }
    }
  }
}

// 移动端开始
@media screen and (max-width: 768px) {
  .newsInfoListBox {
    width: 100%;
    padding: 0 12px;
    margin: 0 auto;
    box-sizing: border-box;
    /deep/ .van-list__error-text {
      font-size: 14px;
      color: #969799;
    }
    /deep/ .van-list__loading {
      font-size: 14px;
      color: #969799;
    }
    /deep/ .van-list__finished-text {
      font-size: 14px;
      color: #969799;
    }

    h1 {
      font-size: 16px;
      font-weight: bold;
      letter-spacing: 2px;
      color: #333333;
      text-align: center;
      margin-top: 24px;
    }

    .search {
      background: #f5f5f5;
      height: 35x;
      margin: 18px 0 0;
      display: flex;
      align-items: center;
      box-sizing: border-box;
      border-radius: 8px 8px 8px 8px;

      input {
        width: 100%;
        height: 100%;
        background: #f5f5f5;
        border: none;
        outline: none;
        padding: 0 10px;
        box-sizing: border-box;
        flex: 1;
        font-size: 14px;
      }

      .right {
        width: 50px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        i {
          font-style: normal;
          font-size: 20px;
        }
      }
    }

    ul {
      .pc {
        display: none;
      }

      .h5 {
        display: flex;
        padding: 8px;
        box-sizing: border-box;
        background-color: #fff;
        border: 1px solid #dbdce1;
        border-radius: 8px;
        margin-top: 20px;
        cursor: pointer;

        img {
          width: 140px;
          height: 86px;
          object-fit: cover;
          border-radius: 4px;
          flex-shrink: 0;
          margin-right: 10px;
        }

        .right {
          flex: 1;
          display: flex;
          flex-flow: column wrap;
          justify-content: space-between;

          h2 {
            font-size: 14px;
            color: #333333;
            font-weight: bold;
          }

          p {
            font-size: 12px;
            color: #a1a1a1;
            font-weight: bold;
          }
        }
      }
    }
  }
}
// 移动端结束

// 平板开始
@media screen and (min-width: 768px) and (max-width: 992px) {
  .newsInfoListBox {
    font-size: 14px;
    padding: 0 8%;
    .search {
      margin: 2.75em 0% 1.875em;
    }
  }
}

// 平板结束

// 中等屏幕开始

@media screen and (min-width: 992px) and (max-width: 1200px) {
  .newsInfoListBox {
    font-size: 18px;
    padding: 0 8%;
    .search {
      margin: 2.75em 0% 1.875em;
    }
  }
}

// 中等屏幕结束

// 大屏幕1200px - 1596px 开始
@media screen and (min-width: 1200px) and (max-width: 1596px) {
  .newsInfoListBox {
    font-size: 20px;
    padding: 0 7%;
    .search {
      margin: 2.75em 0% 1.875em;
    }
  }
}
// 大屏幕1200px - 1596px 结束

// 大屏幕1596px 开始
@media screen and (min-width: 1596px) and (max-width: 2200px) {
  .newsInfoListBox {
    font-size: 20px;
    padding: 0 7%;
  }
}
// 大屏幕1596px 结束

// 大屏幕2200px 开始
@media screen and (min-width: 2200px) {
  .newsInfoListBox {
    font-size: 24px;
    padding: 0 7%;
  }
}
// 大屏幕2200px 结束
</style>




<style lang="less" scoped>
::-webkit-input-placeholder {
  /* WebKit browsers */
  color: #bfbfbf;
}

:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #bfbfbf;
}

::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #bfbfbf;
}

:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: #bfbfbf;
}
</style>

<style scoped >
/deep/ .van-list__error-text {
  font-size: 1em;
  color: #969799;
}
/deep/ .van-list__loading {
  font-size: 1em;
  color: #969799;
}
/deep/ .van-list__finished-text {
  font-size: 1em;
  color: #969799;
}
</style>